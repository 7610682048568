const tan = {
  "status": {
    "status": "U",
    "statusDate": null,
    "phoneNumber": null,
    "errorCounter": 0,
    "sessionTanActive": false,
    "blacklistActive": false,
    "blacklistUntil": null,
    "blacklistComment": null,
    "emergencyModeActive": false
  },
  "registerTanPhone": {
  },
  "registerTanService": {
  },
  "activateTanService": {
  }
}

export default tan
